// todo: maybe load from SEO CMS or our API
export const catalogUrlMap = [
  { url: "/catalog" },

  { url: "/catalog/lichnaja-zhizn", scopes: [1] },
  { url: "/catalog/samorazvitie", scopes: [2] },
  { url: "/catalog/rabota", scopes: [3] },
  { url: "/catalog/sovmestimost", scopes: [4] },
  { url: "/catalog/puteshestvija-i-pereezdy", scopes: [5] },
  { url: "/catalog/finansy", scopes: [6] },
  { url: "/catalog/drugoe", scopes: [7] },
  { url: "/catalog/natalnaya-karta", scopes: [8] }, // todo: maybe add this shpere to other urls
  { url: "/catalog/sinastriya", scopes: [9] }, // todo: maybe add this shpere to other urls,

  { url: "/catalog/astrologi", skills: [1] },
  { url: "/catalog/astrologi/lichnaja-zhizn", skills: [1], scopes: [1] },
  { url: "/catalog/astrologi/samorazvitie", skills: [1], scopes: [2] },
  { url: "/catalog/astrologi/rabota", skills: [1], scopes: [3] },
  { url: "/catalog/astrologi/sovmestimost", skills: [1], scopes: [4] },
  { url: "/catalog/astrologi/puteshestvija-i-pereezdy", skills: [1], scopes: [5] },
  { url: "/catalog/astrologi/finansy", skills: [1], scopes: [6] },
  { url: "/catalog/astrologi/drugoe", skills: [1], scopes: [7] },

  { url: "/catalog/numerologi", skills: [2] },
  { url: "/catalog/numerologi/lichnaja-zhizn", skills: [2], scopes: [1] },
  { url: "/catalog/numerologi/samorazvitie", skills: [2], scopes: [2] },
  { url: "/catalog/numerologi/rabota", skills: [2], scopes: [3] },
  { url: "/catalog/numerologi/sovmestimost", skills: [2], scopes: [4] },
  { url: "/catalog/numerologi/puteshestvija-i-pereezdy", skills: [2], scopes: [5] },
  { url: "/catalog/numerologi/finansy", skills: [2], scopes: [6] },
  { url: "/catalog/numerologi/drugoe", skills: [2], scopes: [7] },

  { url: "/catalog/tarologi", skills: [3] },
  { url: "/catalog/tarologi/lichnaja-zhizn", skills: [3], scopes: [1] },
  { url: "/catalog/tarologi/samorazvitie", skills: [3], scopes: [2] },
  { url: "/catalog/tarologi/rabota", skills: [3], scopes: [3] },
  { url: "/catalog/tarologi/sovmestimost", skills: [3], scopes: [4] },
  { url: "/catalog/tarologi/puteshestvija-i-pereezdy", skills: [3], scopes: [5] },
  { url: "/catalog/tarologi/finansy", skills: [3], scopes: [6] },
  { url: "/catalog/tarologi/drugoe", skills: [3], scopes: [7] },

  { url: "/catalog/matrica-sudby", skills: [4] },
  { url: "/catalog/matrica-sudby/lichnaja-zhizn", skills: [4], scopes: [1] },
  { url: "/catalog/matrica-sudby/samorazvitie", skills: [4], scopes: [2] },
  { url: "/catalog/matrica-sudby/rabota", skills: [4], scopes: [3] },
  { url: "/catalog/matrica-sudby/sovmestimost", skills: [4], scopes: [4] },
  { url: "/catalog/matrica-sudby/puteshestvija-i-pereezdy", skills: [4], scopes: [5] },
  { url: "/catalog/matrica-sudby/finansy", skills: [4], scopes: [6] },
  { url: "/catalog/matrica-sudby/drugoe", skills: [4], scopes: [7] },

  { url: "/catalog/rasstanovki", skills: [5] },
  { url: "/catalog/rasstanovki/lichnaja-zhizn", skills: [5], scopes: [1] },
  { url: "/catalog/rasstanovki/samorazvitie", skills: [5], scopes: [2] },
  { url: "/catalog/rasstanovki/rabota", skills: [5], scopes: [3] },
  { url: "/catalog/rasstanovki/sovmestimost", skills: [5], scopes: [4] },
  { url: "/catalog/rasstanovki/puteshestvija-i-pereezdy", skills: [5], scopes: [5] },
  { url: "/catalog/rasstanovki/finansy", skills: [5], scopes: [6] },
  { url: "/catalog/rasstanovki/drugoe", skills: [5], scopes: [7] },

  { url: "/catalog/psihologicheskoe-konsultirovanie", skills: [6] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/lichnaja-zhizn", skills: [6], scopes: [1] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/samorazvitie", skills: [6], scopes: [2] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/rabota", skills: [6], scopes: [3] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/sovmestimost", skills: [6], scopes: [4] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/puteshestvija-i-pereezdy", skills: [6], scopes: [5] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/finansy", skills: [6], scopes: [6] },
  { url: "/catalog/psihologicheskoe-konsultirovanie/drugoe", skills: [6], scopes: [7] },

  { url: "/catalog/runologi", skills: [7] },
  { url: "/catalog/runologi/lichnaja-zhizn", skills: [7], scopes: [1] },
  { url: "/catalog/runologi/samorazvitie", skills: [7], scopes: [2] },
  { url: "/catalog/runologi/rabota", skills: [7], scopes: [3] },
  { url: "/catalog/runologi/sovmestimost", skills: [7], scopes: [4] },
  { url: "/catalog/runologi/puteshestvija-i-pereezdy", skills: [7], scopes: [5] },
  { url: "/catalog/runologi/finansy", skills: [7], scopes: [6] },
  { url: "/catalog/runologi/drugoe", skills: [7], scopes: [7] },

  { url: "/catalog/chakri", skills: [8] },
  { url: "/catalog/chakri/lichnaja-zhizn", skills: [8], scopes: [1] },
  { url: "/catalog/chakri/samorazvitie", skills: [8], scopes: [2] },
  { url: "/catalog/chakri/rabota", skills: [8], scopes: [3] },
  { url: "/catalog/chakri/sovmestimost", skills: [8], scopes: [4] },
  { url: "/catalog/chakri/puteshestvija-i-pereezdy", skills: [8], scopes: [5] },
  { url: "/catalog/chakri/finansy", skills: [8], scopes: [6] },
  { url: "/catalog/chakri/drugoe", skills: [8], scopes: [7] },

  { url: "/catalog/gipnoterapia", skills: [9] },
  { url: "/catalog/gipnoterapia/lichnaja-zhizn", skills: [9], scopes: [1] },
  { url: "/catalog/gipnoterapia/samorazvitie", skills: [9], scopes: [2] },
  { url: "/catalog/gipnoterapia/rabota", skills: [9], scopes: [3] },
  { url: "/catalog/gipnoterapia/sovmestimost", skills: [9], scopes: [4] },
  { url: "/catalog/gipnoterapia/puteshestvija-i-pereezdy", skills: [9], scopes: [5] },
  { url: "/catalog/gipnoterapia/finansy", skills: [9], scopes: [6] },
  { url: "/catalog/gipnoterapia/drugoe", skills: [9], scopes: [7] },
];

import React, { useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import { SessionStatus } from "@/autoGeneratedGlobalTypes";
import ExpertStatus from "@/components/common/expertStatus";
import GlobalContext from "@/contexts/Global/GlobalContext";
import { GlobalContextType } from "@/contexts/Global/types";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import CallButtons from "@components/common/callButtons";

import NavigationPanel from "./navigationPanel";
import StatisticsInfo from "./statisticsInfo";
import { TopBlockProps } from "./types";
import "./styles.scss";

const TopBlock = ({ expert }: TopBlockProps) => {
  const { isUserLoggedIn, freeMinutesCount } = useContext<UserContextType>(UserContext);
  const { alertStatus } = useContext<GlobalContextType>(GlobalContext);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position > 480) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* fix call button on the expert page */}
        <html className="html--no-overflow" lang="ru" />
        {/* only for Carrot Quest */}
        <body
          className={`${
            !isUserLoggedIn || freeMinutesCount
              ? "footer--big-indent-get-free-minutes"
              : "footer--big-indent"
          } carrot-quest--extra-button`}
        />
      </Helmet>
      <div className="top-block__container">
        <NavigationPanel skill={expert.skills?.[0]} />
        <div className="top-block__image">
          <picture>
            <source
              srcSet={`${expert.cover?.vertical1x},
          ${expert.cover?.vertical2x} 2x,
          ${expert.cover?.vertical3x} 3x`}
              media="(max-width: 767px)"
            />
            <source
              srcSet={`${expert.cover?.horizontal1x},
          ${expert.cover?.horizontal2x} 2x,
          ${expert.cover?.horizontal2x} 3x`}
              media="(min-width: 768px)"
            />
            <img src={expert.cover?.horizontal2x || ""} alt="" className="top-block__image-img" />
          </picture>
        </div>
        <div className="top-block__parent">
          <div className="top-block__info-panel">
            <div className="top-block__info">
              <div className="top-block__skills order-skill">
                {expert.skills
                  ?.slice()
                  .sort((a, b) =>
                    (b.badge === "Таролог" ? 1 : -1))
                  .map((skill) =>
                    skill.badge).join(", ")}
              </div>
              <h1 className="top-block__name order-name">{expert.name}</h1>
              <StatisticsInfo
                experienceInYears={expert.experienceInYears}
                rating={expert.rating}
                reviewsCount={expert.reviewsCount}
                totalSessionsCount={expert.totalSessionsCount}
              />
              <div className="top-block__isonline  order-status">
                <ExpertStatus status={expert.state} />
              </div>

              <div className={`top-block__appointment ${scrolled ? "scrolled" : ""}`}>
                <div
                  className={`top-block__appointment-content ${scrolled ? "scrolled-content" : ""}`}
                >
                  <CallButtons
                    expertId={expert.id}
                    expertRates={expert.rates}
                    expertState={expert.state}
                    freeMinutesCount={freeMinutesCount}
                    isUserLoggedIn={isUserLoggedIn}
                    alertStatus={alertStatus as SessionStatus}
                    trialAllowance={expert.trialAllowance}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBlock;

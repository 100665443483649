import { isBrowser } from "@/utils/env";

export const getActualDescription = (expertDescription: string, description: string): string =>
  (isBrowser()
    ? expertDescription
      .match(/<p>(.*?)<\/p>/g)?.[0]
      ?.replaceAll(/<\/?p>/g, "")
      .replaceAll("&nbsp;", " ")
      .replaceAll("\n", " ") || ""
    : description);

export const getActualTitle = (expertName: string, title: string): string =>
  (isBrowser() ? `${expertName} — lunaro.ru` : title);

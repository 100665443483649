import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import { IconTypeEnum } from "@/components/common/icon";
import { marketingAudioGreetingPlayHandler } from "@/marketing/utils";

import MobileAudioGreeting from "./components/mobileAudioGreeting";
import { audioExpertsId } from "./constants";
import { tempAudioMap } from "./tempAudioMap";
import { AudioGreetingProps } from "./types";
import "./styles.scss";

const AudioGreeting = ({ expertId }: AudioGreetingProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [show, setShow] = useState(false);
  const [progresWidth, setProgresWidth] = useState(0);
  const [wasAudioPlayed, setWasAudioPlayed] = useState<boolean>(false);
  const isPaused = audioRef.current?.paused;
  const isEnded = audioRef.current?.ended;

  const audio = `/audioGreeting/${tempAudioMap[expertId]}_audio.mp3`;

  const togglePlayHandler = () => {
    if (audioRef.current?.paused) {
      audioRef.current?.play();
      if (!wasAudioPlayed) {
        setWasAudioPlayed(true);
        marketingAudioGreetingPlayHandler();
      }
    } else {
      audioRef.current?.pause();
    }
  };

  const getWitdhProgresBar = () => {
    const currentTime = audioRef.current?.currentTime || 0;
    const duration = audioRef.current?.duration || 0;
    const width = (currentTime / duration) * 100;
    setProgresWidth(+width);
  };

  useEffect(() => {
    const findAudioExpert = audioExpertsId.find((id) =>
      tempAudioMap[expertId] === id);
    if (findAudioExpert) {
      setShow(true);
    } else {
      setShow(false);
    }

    audioRef.current?.addEventListener("timeupdate", getWitdhProgresBar);

    return () => {
      audioRef.current?.removeEventListener("timeupdate", getWitdhProgresBar);
    };
  }, [audioRef.current]);

  return (
    <>
      {show && (
        <div className="audio-greeting">
          <Button
            text="Слушать аудиоприветствие"
            size={ButtonSizeEnum.Middle}
            iconPosition={ButtonIconPositionEnum.Left}
            icon={isPaused ? IconTypeEnum.Play : IconTypeEnum.Pause}
            color={ButtonColorEnum.Light}
            className="audio-greeting__icon-play"
            onClick={togglePlayHandler}
          >
            <div
              style={{
                width: `${isEnded ? 0 : progresWidth}%`,
              }}
              className="audio-greeting__progres-audio"
            />
          </Button>

          <MobileAudioGreeting
            progresAudioWidth={progresWidth}
            togglePlayHandler={togglePlayHandler}
            iconAudio={audioRef.current?.paused ? IconTypeEnum.Play : IconTypeEnum.Pause}
            isEnded={isEnded}
          />
          <audio src={audio} ref={audioRef} />
        </div>
      )}
    </>
  );
};

export default AudioGreeting;
